import { render, staticRenderFns } from "./settlementDetail.vue?vue&type=template&id=136cde6a&scoped=true&"
import script from "./settlementDetail.vue?vue&type=script&lang=js&"
export * from "./settlementDetail.vue?vue&type=script&lang=js&"
import style0 from "./settlementDetail.vue?vue&type=style&index=0&id=136cde6a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136cde6a",
  null
  
)

export default component.exports