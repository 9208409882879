<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>项目结算单</b></span>
            <div class="flex-center" @click="back"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>
        </div>
        <div class="project-info">
            <div class="settlement-tab">
                <div class="detail-info">
                    <h3>会议结算单</h3>
                    <p>会议名称：{{ name }}</p>
                    <p>
                        <span>会议日期：{{ start_time }} ~ {{ end_time }}</span>
                        <span>大会主席：{{ chairman }}</span>
                    </p>
                    <h4><span>一、收款明细</span>
                        <el-button size="medium" type="primary" @click="addTable1 = true" v-if="eid === 0">新增
                        </el-button>
                    </h4>
                    <p class="active">单位：万元 </p>
                </div>
                <el-table
                        :data="settlement1"
                        header-row-class-name="table-header"
                        style="width: 100%">
                    <el-table-column
                            width="100"
                            label="序号"
                            type="index"
                            align="center"
                    >
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num1"
                            label="赞助单位"
                    >
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num2"
                            label="赞助金额"
                    >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num2 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num3"
                            label="到账金额"
                    >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num3 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num4"
                            label="服务费+税金"
                    >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num4 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num5"
                            label="可用专项基金"
                    >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num5 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="eid === 0"
                            label="操作"
                            width="200px"
                            align="center"
                    >
                        <template slot-scope="scope">
                            <div class="table-operate">
                                <el-button type="text" size="small" style="font-size: 14px;"
                                           @click="editItem(scope.$index)">编辑
                                </el-button>
                                <el-button type="text" size="small" style="font-size: 14px; color: #e4393c;"
                                           @click="closeItem(scope.$index,1)">删除
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table
                        :data="total"
                        class="table-total"
                        :show-header="false"
                        style="width: 100%;background: #f2f2f2;">
                    <el-table-column
                            width="100"
                            align="center"
                    >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num1 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num2 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num3 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num4 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num5 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="eid === 0"
                            label=" "
                            width="200px"
                    >
                    </el-table-column>
                </el-table>

                <div class="detail-info">
                    <h4>二、付款明细
                        <el-button size="medium" type="primary" @click="addTable2 = true" v-if="eid === 0">新增
                        </el-button>
                    </h4>
                    <p class="active">单位：万元 </p>
                </div>
                <el-table
                        :data="settlement2"
                        header-row-class-name="table-header"
                        style="width: 100%">
                    <el-table-column
                            width="100"
                            label="序号"
                            type="index"
                            align="center"
                    >
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num1"
                            label="付款项目"
                    >
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num2"
                            label="付款金额"
                    >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num2 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num3"
                            label="收款单位"
                    >
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="num4"
                            label="专项基金余额"
                    >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num4 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="eid === 0"
                            label="操作"
                            width="200px"
                            align="center"
                    >
                        <template slot-scope="scope">
                            <div class="table-operate">
                                <el-button type="text" size="small" style="font-size: 14px;"
                                           @click="editItem2(scope.$index)">编辑
                                </el-button>
                                <el-button type="text" size="small" style="font-size: 14px; color: #e4393c;"
                                           @click="closeItem(scope.$index,2)">删除
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table
                        :data="total2"
                        class="table-total"
                        :show-header="false"
                        style="width: 100%;background: #f2f2f2;">
                    <el-table-column
                            width="100"
                            align="center"
                            prop="name"

                    >
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop=""
                    >
                    </el-table-column>
                    <el-table-column
                            align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num1 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num2 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.num3 | tofixed }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="eid === 0"
                            label=" "
                            width="200px"
                            align="center"
                    >
                    </el-table-column>
                </el-table>


                <el-button type="primary" class="save-btn" @click="saveAll" v-if="eid === 0">保存</el-button>
            </div>
        </div>
        <el-dialog
                :visible="addTable1"
                :close-on-click-modal="false"
                width="550px"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">{{ setItem ? '编辑收款明细' : '新增收款明细' }}</span>
            <div class="editForm">
                <div class="dialog-container flex-center">
                    <div>赞助单位</div>
                    <el-input v-model="table1.num1" placeholder="请输入"></el-input>
                </div>
                <div class="dialog-container flex-center">
                    <div>赞助金额</div>
                    <el-input v-model="table1.num2" placeholder="请输入"></el-input>
                    <span>万元</span>
                </div>
                <div class="dialog-container flex-center">
                    <div>到账金额</div>
                    <el-input v-model="table1.num3" placeholder="请输入"></el-input>
                    <span>万元</span>
                </div>
                <div class="dialog-container flex-center">
                    <div>服务费+税金</div>
                    <el-input v-model="table1.num4" placeholder="请输入"></el-input>
                    <span>万元</span>
                </div>
                <div class="dialog-container flex-center">
                    <div>可用专项基金</div>
                    <el-input v-model="table1.num5" placeholder="请输入"></el-input>
                    <span>万元</span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
         <el-button @click="closeDialog">取消</el-button>
         <el-button type="primary" @click="saveTable1">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible="addTable2"
                :close-on-click-modal="false"
                width="550px"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">{{ setItem ? '编辑付款明细' : '新增付款明细' }}</span>
            <div class="editForm">
                <div class="dialog-container flex-center">
                    <div>付款项目</div>
                    <el-input v-model="table2.num1" placeholder="请输入"></el-input>
                </div>
                <div class="dialog-container flex-center">
                    <div>付款金额</div>
                    <el-input v-model="table2.num2" placeholder="请输入"></el-input>
                    <span>万元</span>
                </div>
                <div class="dialog-container flex-center">
                    <div>收款单位</div>
                    <el-input v-model="table2.num3" placeholder="请输入"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
         <el-button @click="closeDialog">取消</el-button>
         <el-button type="primary" @click="saveTable2">确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "settlementDetail",
        data() {
            return {
                id: '',
                eid: 0,
                name: '',
                start_time: '',
                end_time: '',
                chairman: '',
                settlement1: [],

                total: [
                    {
                        name: '合计',
                        num1: '--',
                        num2: 0.0000,
                        num3: 0.0000,
                        num4: 0.0000,
                        num5: 0.0000,
                    }
                ],
                settlement2: [],
                total2: [
                    {
                        name: '合计',
                        num1: 0.0000,
                        num2: '--',
                        num3: 0.0000
                    }
                ],
                addTable1: false,
                addTable2: false,
                setItem: '',
                table1: {
                    num1: '',
                    num2: '',
                    num3: '',
                    num4: '',
                    num5: '',
                },
                table2: {
                    num1: '',
                    num2: '',
                    num3: ''
                }
            }
        },
        created() {
            this.id = this.$route.query.id
            this.$emit('setIndex', [1, 1])
            this.init()
        },
        methods: {
            init() {
                this.$api.apiContent.specialTableInfo({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.id,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                    }, this.$store.state.user.key)
                }).then(res => {

                    this.name = res.data.name
                    this.eid = res.data.eid
                    this.start_time = this.$tools.ZHDate(new Date(res.data.start_time * 1000))
                    this.end_time = this.$tools.ZHDate(new Date(res.data.end_time * 1000))
                    this.chairman = res.data.chairman
                    if (res.data.collection_detailed) {
                        this.settlement1 = res.data.collection_detailed
                        this.calcTotal()
                    }
                    if (res.data.payment_detailed) {
                        this.settlement2 = res.data.payment_detailed
                        this.calcTotal2()
                    }
                })
            },

            saveAll() {
                if (this.settlement1.length === 0 || this.settlement2.length === 0) {
                    return this.$message.error('会议结算单未填写完整')
                }
                this.$api.apiContent.specialTableSave({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.id,
                    collection_detailed: JSON.stringify(this.settlement1),
                    payment_detailed: JSON.stringify(this.settlement2),
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        collection_detailed: JSON.stringify(this.settlement1),
                        payment_detailed: JSON.stringify(this.settlement2),
                    }, this.$store.state.user.key)
                }).then(() => {
                    this.$message.success('保存成功')
                }).catch(()=>{
                    this.$message.success('保存成功')
                })
            },


            editItem(index) {
                this.setItem = index
                this.table1 = {
                    num1: this.settlement1[index].num1,
                    num2: this.settlement1[index].num2,
                    num3: this.settlement1[index].num3,
                    num4: this.settlement1[index].num4,
                    num5: this.settlement1[index].num5,
                }
                this.addTable1 = true
            },
            saveTable1() {
                if (!this.table1.num1) {
                    return this.$message.error('请输入赞助单位')

                }
                if (!this.table1.num2) {
                    return this.$message.error('请输入赞助金额')

                }
                if (!this.table1.num3) {
                    return this.$message.error('请输入到账金额')

                }
                if (!this.table1.num4) {
                    return this.$message.error('请输入服务费+税金')

                }
                if (!this.table1.num5) {
                    return this.$message.error('请输入会议可用基金')
                }
                if (this.setItem !== '') {
                    this.$set(this.settlement1, this.setItem, this.table1)
                } else {
                    this.settlement1.push(this.table1)
                }
                this.calcTotal()
                this.upTable2()
                this.closeDialog()

            },
            calcTotal() {
                this.total = [
                    {
                        name: '合计',
                        num1: '--',
                        num2: 0,
                        num3: 0,
                        num4: 0,
                        num5: 0,
                    }
                ]
                this.settlement1.map(item => {
                    this.total[0].num2 += item.num2 * 1
                    this.total[0].num3 += item.num3 * 1
                    this.total[0].num4 += item.num4 * 1
                    this.total[0].num5 += item.num5 * 1
                })
                this.upTable2()
            },

            editItem2(index) {
                this.setItem = index
                this.table2 = {
                    num1: this.settlement2[index].num1,
                    num2: this.settlement2[index].num2,
                    num3: this.settlement2[index].num3,
                    num4: this.settlement2[index].num4,
                }
                this.addTable2 = true
            },
            saveTable2() {
                if (!this.table2.num1) {
                    return this.$message.error('请输入付款项目')
                }
                if (!this.table2.num2) {
                    return this.$message.error('请输入付款金额')
                }
                if (!this.table2.num3) {
                    return this.$message.error('请输入收款单位')
                }

                if (this.setItem !== '') {
                    this.$set(this.settlement2, this.setItem, this.table2)
                } else {
                    this.settlement2.push(this.table2)
                }
                this.upTable2()

                this.closeDialog()
            },
            upTable2() {
                this.settlement2.map((item, index) => {
                    if (index === 0) {
                        item.num4 = this.total[0].num5 * 1 - item.num2 * 1
                        this.$set(this.settlement2, index, item)
                    } else {
                        item.num4 = this.settlement2[index - 1].num4 - item.num2 * 1
                        this.$set(this.settlement2, index, item)
                    }
                })
                this.calcTotal2()
            },
            calcTotal2() {
                this.total2 = [
                    {
                        name: '合计',
                        num1: 0.0000,
                        num2: '--',
                        num3: 0.0000
                    }
                ]
                this.settlement2.map(item => {
                    this.total2[0].num1 += item.num2 * 1
                })
                this.total2[0].num3 = this.total[0].num5 * 1 - this.total2[0].num1 * 1
            },

            //  关闭弹框
            closeDialog() {
                this.addTable1 = false
                this.addTable2 = false
                this.setItem = ''
                this.table1 = {
                    num1: '',
                    num2: '',
                    num3: '',
                    num4: '',
                    num5: '',
                }
                this.table2 = {
                    num1: '',
                    num2: '',
                    num3: '',
                    num4: ''
                }
            },
            closeItem(index, tab) {
                this.$confirm('此操作将删除该' + (tab === 1 ? '收款明细' : '付款明细') + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (tab === 1) {
                        this.settlement1.splice(index, 1)
                        this.calcTotal()
                    } else {
                        this.settlement2.splice(index, 1)
                        this.upTable2()
                        this.calcTotal()
                    }

                })
            },
            //  返回
            back() {
                this.$router.go(-1)
            }
        },
        filters: {
            tofixed(val) {
                return parseFloat(Number((val * 1)).toFixed(6))
            }
        }
    }
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }

            }
        }

        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            min-height: 750px;

            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .title-tab {
                    .active {
                        background: #3b77e7;
                        color: #fff;
                    }

                    .settlement {
                        margin-left: 10px;
                    }
                }

                .el-select {
                    margin-right: 20px;
                }

                .el-input {
                    margin: 0 20px;
                    width: 268px;
                }
            }

            .settlement-tab {
                padding: 30px;

                .detail-info {
                    h3 {
                        text-align: center;
                        font-size: 24px;
                        line-height: 36px;
                    }

                    h4 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        font-size: 18px;
                        margin-bottom: 15px;
                    }

                    p {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 6px;

                        &.active {
                            justify-content: flex-end;
                        }
                    }
                }
            }

            .save-btn {
                display: block;
                width: 120px;
                margin: 30px auto;
            }
        }
    }

    .editForm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .dialog-container {
            width: 100%;
            padding: 0 20px;
            margin-bottom: 20px;
            font-size: 20px;
            color: #333333;

            &.active {
                width: 100%;

                .el-textarea {
                    flex: 1;
                    margin: 0 10px;
                }
            }

            div {
                width: 120px;
                flex-shrink: 0;
            }

            .el-input, .el-radio-group, .el-textarea, .el-textarea__inner {
                flex-grow: 1;
                margin: 0 10px;
                font-size: 16px;
            }
        }
    }

    .dialog-title {
        font-size: 26px;
        display: block;
        line-height: 30px;
        margin-top: 20px;
    }
</style>